import React from 'react';
import { Container, Box, Typography, List, ListItem, ListItemIcon, ListItemText, CssBaseline, ThemeProvider } from '@mui/material';
import PresentationIcon from '@mui/icons-material/School';
import ContentsIcon from '@mui/icons-material/Description';
import ActivitiesIcon from '@mui/icons-material/Edit';
import SummaryIcon from '@mui/icons-material/CheckCircle';
import ResourcesIcon from '@mui/icons-material/LibraryBooks';
import ReferencesIcon from '@mui/icons-material/MenuBook';
import darkTheme from './theme';
import backgroundImage from '../images/fundo_bg.png'; // Atualize o caminho para o correto

function About() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, mb: 6 }}>
            <Typography variant="h4" gutterBottom>Organização dos Módulos</Typography>
            <Typography variant="body1" paragraph>
              Todos os módulos serão organizados uma trilha com as seguintes sessões:
            </Typography>
            <List>
              {[
                { icon: <PresentationIcon />, primary: "APRESENTAÇÃO", secondary: "Breve descrição dos objetivos e tema do módulo" },
                { icon: <ContentsIcon />, primary: "CONTEÚDOS", secondary: "Apresenta os conceitos biológicos relevantes para a construção do conhecimento" },
                { icon: <ActivitiesIcon />, primary: "ATIVIDADES", secondary: "Atividades interativas para desenvolver os conhecimentos prévios, novas reflexões e realizar a verificação de aprendizagem" },
                { icon: <SummaryIcon />, primary: "RESUMO", secondary: "Revisa os principais conteúdos abordados no módulo" },
                { icon: <ResourcesIcon />, primary: "RECURSOS", secondary: "Sugestão de material complementar para consulta e aprofundamento do tema do módulo" },
                { icon: <ReferencesIcon />, primary: "REFERÊNCIAS", secondary: "Apresenta referencial teórico utilizado para a elaboração dos conteúdos apresentados" }
              ].map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    border: `1px solid ${darkTheme.palette.primary.main}`, // Adiciona a borda na cor principal do tema
                    borderRadius: '8px', // Arredondamento dos cantos
                    mb: 2, // Margem inferior entre os itens
                    padding: '10px', // Espaçamento interno do item
                  }}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.primary} secondary={item.secondary} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default About;
