import React, { useState } from 'react';
import { Container, Box, Typography, Card, CardContent, Button, TextField, CssBaseline, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const HighlightedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 'bold',
  fontSize: '1rem',
  marginBottom: theme.spacing(2),
}));

const MuralContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  backgroundColor: theme.palette.background.default,
}));

const DespedidaPage = () => {
  const [muralText, setMuralText] = useState('');

  const handleMuralChange = (event) => {
    setMuralText(event.target.value);
  };

  const handleMuralSubmit = () => {
    alert(`Mensagem enviada: ${muralText}`);
    setMuralText('');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            Despedida e Reflexão
          </Heading>
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <Paragraph variant="body1">
                Caros estudantes, finalmente chegamos ao final da nossa jornada em busca da justiça através do DNA!
                Aqui conseguimos descobrir a importância da genética para a resolução de casos forenses, tais como crimes, acidentes e a busca por pessoas desaparecidas.
                Conseguimos compreender como é possível analisar o DNA por meio de técnicas de biologia molecular, utilizando softwares e bancos de dados que nos permitem realizar a identificação de pessoas.
                Dessa forma, esperamos que a sua experiência com o aplicativo Helix tenha sido proveitosa e significativa na sua aprendizagem sobre os conteúdos de Genética Molecular, Genética Forense e Biologia Molecular.
                Obrigada por embarcar conosco nessa aventura pelo universo da Investigação Criminal e Genética Forense!
              </Paragraph>
              <HighlightedText variant="body1">
                Reflita: Você conseguiu absorver todas essas informações e conteúdos apresentados? As hipóteses levantadas no módulo 2 foram confirmadas ou refutadas?
              </HighlightedText>
              <Paragraph variant="body1">
                Deixe no <strong>MURAL DE DESPEDIDA</strong> as suas sensações e percepções ao estudar genética por meio deste aplicativo. Nos conte o que achou dos conteúdos, dos recursos e das atividades propostas.
              </Paragraph>
            </CardContent>
          </Card>

          <MuralContainer>
            <SubHeading variant="h6" gutterBottom>
              Mural de Despedida
            </SubHeading>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Escreva aqui sua mensagem..."
              value={muralText}
              onChange={handleMuralChange}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleMuralSubmit}>
                Enviar
              </Button>
            </Box>
          </MuralContainer>
        </BackgroundBox>
      </Container>
    </ThemeProvider>
  );
};

export default DespedidaPage;
