import React, { useState } from 'react';
import { Container, Box, Typography, Button, Card, CardContent, CssBaseline, ThemeProvider, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const ClickableBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const ForensicScience = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent('');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            Perícia Criminal e Genética Forense
          </Heading>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Dusting_for_fingerprints-wikimedia-300x202.jpg" alt="Perícia Criminal e Genética Forense" style={{ width: '50%', borderRadius: '8px' }} />
            <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
              US Air Force, Public domain, via Wikimedia Commons
            </Typography>
          </Box>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTAÇÃO:
              </SubHeading>
              <Paragraph variant="body1">
                Qual é a função de um Perito Criminal? Como a Biologia auxilia o trabalho desses profissionais? O que é a Genética Forense?
                Essas e outras perguntas serão esclarecidas neste módulo, onde aprenderemos qual é o papel da Perícia Criminal e como o estudo da Genética pode auxiliar na resolução de casos forenses.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                OBJETIVOS:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Compreender as aplicações da Perícia Criminal e da Genética Forense; <br />
                ✓ Refletir sobre como a Genética pode contribuir na resolução de casos forenses.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                CONTEÚDOS:
              </SubHeading>
              <Paragraph variant="body1">
                <strong>1. Ciências Forenses, Criminalística, Perícia Criminal e Genética Forense</strong>
              </Paragraph>
              <Paragraph variant="body1">
                <strong>CLIQUE NOS TERMOS ABAIXO PARA COMPREENDER O QUE CADA UMA DESSAS ÁREAS ESTUDA!</strong>
              </Paragraph>
              <ClickableBox onClick={() => handleOpenModal(
                "Ciências Forenses: Área ampla que utiliza os conhecimentos de várias outras ciências, tais como Medicina, Química, Física, Biologia e Engenharias para analisar vestígios e responder questões de interesse da Justiça."
              )}>
                <strong>Ciências Forenses</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "Criminalística: Consiste na organização e sistematização dos conhecimentos de todas essas áreas com o objetivo de padronizar técnicas e metodologias adequadas para a elucidação de infrações penais."
              )}>
                <strong>Criminalística</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "Perícia Criminal: É a aplicação do Método Científico para coletar, analisar e interpretar vestígios de cenas de crime, através da realização de exames específicos, que visam a aplicação da Justiça e resolução de casos de interesse forense."
              )}>
                <strong>Perícia Criminal</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "Genética Forense: Área de atuação da Perícia Criminal, que utiliza dos conhecimentos da genética e técnicas de biologia molecular para identificar indivíduos através da análise do DNA (material genético) a fim de auxiliar na resolução de casos forenses."
              )}>
                <strong>Genética Forense</strong>
              </ClickableBox>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                ATIVIDADES:
              </SubHeading>
              <Paragraph variant="body1">
                <strong>1. Vamos tentar desvendar o mistério da cena de crime?</strong><br />
                Após analisar o cenário da simulação em realidade virtual do <strong>MÓDULO 1</strong>, use suas palavras e ideias para descrever como você imagina que a <strong>genética</strong> pode nos ajudar a solucionar esse caso intrigante.
                Reflita sobre o poder da genética para revelar informações valiosas, como a identificação das pessoas envolvidas e até mesmo das vítimas.
              </Paragraph>
              <Paragraph variant="body1">
                Com base nas suas reflexões, compartilhem no <strong>Fórum 2 - Módulo 2 – “Formulando Hipóteses”</strong> as suas <strong>hipóteses</strong> sobre como a genética pode contribuir para resolver casos forenses.
              </Paragraph>
              <Paragraph variant="body1" sx={{ color: darkTheme.palette.primary.main }}>
                <strong>Questão-problema: Como a genética pode contribuir para a resolução de casos forenses?</strong>
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RESUMO:
              </SubHeading>
              <Paragraph variant="body1">
                Nesse módulo nós aprendemos: <br />
                ✓ A diferença entre os conceitos de Ciências Forenses, Criminalística, Perícia Criminal e Genética Forense; <br />
                ✓ Como os peritos utilizam o método científico para solucionar casos de interesse forense; <br />
                ✓ As aplicações da Perícia Criminal e as áreas de atuação da Genética Forense; <br />
                Por fim, formulamos hipóteses sobre como a Genética pode contribuir na resolução de casos forenses.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RECURSOS:
              </SubHeading>
              <Paragraph variant="body1">
                Imagem deste módulo: US Air Force, Public domain, via Wikimedia Commons.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                REFERÊNCIAS:
              </SubHeading>
              <Paragraph variant="body1">
                ANTONIO VELHO, J.; GEISER, G. C.; ESPINDULA, A. <strong>Ciências Forenses – Uma introdução às principais áreas da Criminalística Moderna</strong>. 4. ed. Campinas/SP: Millennium Editora, 2021. <br />
                DE ROSA, C. T. A.; STUMVOLL, V. P. <strong>Criminalística</strong>. 8. ed. Campinas/SP: Millennium Editora, 2023. CAPITULO 1, PG 4 A 6. <br />
                DIAS FILHO, C. R.; MENEZES, M. A. M.; FRANCEZ, P. A. da C. História da genética Forense. In: DIAS FILHO, C. R.; et al. <strong>Introdução à genética forense</strong>. Campinas, SP: Millenium Editora, 2020. Capítulo 1, p.1-12. <br />
                DOS REIS, A. B. <strong>Metodologia Científica em Perícia Criminal</strong>. 3. ed. Série: Tratado de Perícias Criminalísticas. Campinas/SP: Millennium Editora, 2016. <br />
                DOS SANTOS, A. E. As principais linhas da biologia forense e como auxiliam na resolução de crimes. <strong>Revista brasileira de criminalística</strong>, v. 7, n. 3, p. 12-20, 2018. <br />
                GRIFFITHS, A. J. F.; WESSLER, S. R.; CARROL, S. B; <em>et al</em>. <strong>Introdução à Genética</strong>. 10ª Edição. Rio de Janeiro: Ed Guanabara Koogan, 2013. <br />
                PIERCE, B. A. <strong>Genética:</strong> Um enfoque conceitual. 5ª Edição. Rio de Janeiro: Ed Guanabara Koogan, 2016.
              </Paragraph>
            </CardContent>
          </Card>
        </BackgroundBox>
      </Container>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent.split(':')[0]}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent.split(':')[1]}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Fechar
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}

export default ForensicScience;
