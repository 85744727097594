import React from 'react';
import { Container, Box, Typography, CssBaseline, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import darkTheme from './theme';
import backgroundImage from '../images/fundo_bg.png'; // Atualize o caminho para o correto

function Home() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, mb: 12 }}>
            <Typography variant="h4" gutterBottom>Bem-vindo(a)!</Typography>
            
            <Box
              component="img"
              src="/btn/1.png"
              alt="Investigando uma Cena de Crime"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/crime-scene-investigation')}
            />
            
            <Box
              component="img"
              src="/btn/2.png"
              alt="Perícia Criminal e Genética Forense"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/forensic-science')}
            />
            
            <Box
              component="img"
              src="/btn/3.png"
              alt="Conceitos Básicos em Genética"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/basic-genetics')}
            />
            
            <Box
              component="img"
              src="/btn/4.png"
              alt="Amostras Biológicas e Identificação Humana"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/biological-samples')}
            />
            
            <Box
              component="img"
              src="/btn/5.png"
              alt="Análises do DNA, Softwares e Bancos de Dados"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/dna-analysis')}
            />

            <Box
              component="img"
              src="/btn/6.png"
              alt="Periciando (Estudos de Caso)"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/ForensicCases')}
            />
            <Box
              component="img"
              src="/btn/8.png"
              alt="Despedida"
              sx={{ mt: 2, cursor: 'pointer', width: '100%', maxWidth: 400, transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
              onClick={() => navigate('/DespedidaPage')}
            />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Home;
