import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Container, Box, Link } from '@mui/material';
import logo from './logo.png';
import Aviso from './Aviso';
import darkTheme from './theme';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import backgroundImage from '../images/fundo_bg.png'; // Atualize o caminho para o correto

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAviso, setShowAviso] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://athixdev.xyz/helix/login.php', { email, password });
      if (response.data.message === "Login successful") {
        setIsAuthenticated(true);
        if (localStorage.getItem('hasAcceptedTerms') === 'true') {
          navigate('/home');
        } else {
          setShowAviso(true);
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    localStorage.setItem('hasAcceptedTerms', 'true');
    setShowAviso(false);
    navigate('/home');
  };

  const handleDecline = () => {
    setShowAviso(true);
  };

  if (showAviso) {
    return <Aviso onAccept={handleAccept} onDecline={handleDecline} />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover', // Faz com que a imagem cubra toda a largura e altura da tela
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: '100%', // Garante que o Box tenha a largura completa da tela
          minHeight: '100vh', // Garante que o Box ocupe toda a altura da tela
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ marginTop: 0 }}>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt="Login" style={{ width: '150px', marginBottom: '20px' }} />
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? 'Entrando...' : 'Entrar'}
              </Button>
              <Button
                fullWidth
                variant="text"
                onClick={() => navigate('/forgot-password')}
              >
                Esqueceu a Senha?
              </Button>
            </Box>
            <Button
              fullWidth
              variant="text"
              onClick={() => navigate('/register')}
            >
              Cadastre-se
            </Button>
            <Link
              href="https://apphelix.com.br/politica-privacidade.html"
              target="_blank"
              rel="noopener"
              sx={{ mt: 2 }}
            >
              Política de Privacidade
            </Link>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Login;
