import React, { useState } from 'react';
import { Container, Box, Typography, Card, CardContent, Button, CssBaseline, ThemeProvider, Modal, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  marginBottom: theme.spacing(2),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const DNAAnalysis = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent('');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            <strong>Análises do DNA, Softwares e Bancos de Dados</strong>
          </Heading>
          <Box textAlign="center" mb={4}>
            <img 
              src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Agarose_gel_with_UV_illumination_-_Ethidium_bromide_stained_DNA_glows_orange_close-up-scaled.jpg" 
              alt="Observação das bandas de DNA em Gel de Agarose na Luz UV. School of Natural Resources from Ann Arbor, CC BY 2.0 via Wikimedia Commons" 
              width="654"
              height="435"
            />
          </Box>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTAÇÃO:
              </SubHeading>
              <Paragraph variant="body1">
                Neste módulo, iremos conhecer quais são as técnicas de Biologia Molecular utilizadas para realizar análises de DNA.
                Além disso, descobriremos quais são os Softwares e Bancos de dados disponíveis para comparação de amostras e busca por coincidências entre perfis de DNA.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                OBJETIVOS:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Caracterizar os princípios e técnicas envolvidas nas análises do DNA humano; <br />
                ✓ Compreender o processo de geração e comparação de perfis genéticos; <br />
                ✓ Reconhecer a importância dos softwares e bancos de perfis genéticos para a identificação humana e resolução de casos forenses.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                CONTEÚDOS:
              </SubHeading>
              <Paragraph variant="body1">
                <strong>Como o DNA é analisado?</strong><br />
                As análises de DNA feitas com a finalidade de elucidar casos de interesse forense precisam seguir conhecimentos científicos e tecnológicos específicos, além de apresentar uma metodologia que garanta que as amostras sejam coletadas, documentadas, preservadas e analisadas de forma correta.
              </Paragraph>
              <Paragraph variant="body1" sx={{ fontWeight: 'bold', color: darkTheme.palette.secondary.main }}>
                CLIQUE NAS CAIXAS ABAIXO PARA CONHECER AS ETAPAS DA ANÁLISE DO DNA!
              </Paragraph>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Coleta e acondicionamento do Material Genético: Como vimos no módulo anterior, os vestígios biológicos encontrados em cenas de crime são coletados, corretamente acondicionados e encaminhados para os laboratórios de análises forenses para exames posteriores. No caso das amostras de referência, coletadas de objetos da vítima, de familiares ou suspeitos, a coleta de DNA deve ser feita mediante técnica adequada e indolor.')}>
                    Coleta e acondicionamento do Material Genético
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Extração de DNA: A extração é uma técnica que consiste em separar o DNA de outras substâncias indesejáveis da célula, chamadas de contaminantes (ex: proteínas, lipídios, carboidratos), por meio de um processo composto por três etapas básicas: lise das membranas lipídicas, purificação e precipitação do DNA. Pode ser feita de forma manual ou automatizada.')}>
                    Extração de DNA
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Quantificação: A quantificação é uma técnica para mensurar a quantidade de DNA total presente em uma amostra, ou seja, sua concentração. Pode ser feita através do uso do espectrofotômetro ou do fluorímetro.')}>
                    Quantificação
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Amplificação (PCR): A Reação em Cadeia da Polimerase (PCR), é uma técnica que amplifica o material genético de forma exponencial, resultando em bilhões de cópias. Dessa forma, é possível analisar o DNA de cenas de crime, que geralmente é encontrado degradado ou em pequenas quantidades. É composta por três etapas básicas: a desnaturação, o anelamento e a extensão.')}>
                    Amplificação (PCR)
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Eletroforese: A Eletroforese é uma técnica que nos possibilita observar o DNA extraído. Essa técnica consiste em separar o DNA, por diferença de carga e tamanho (em fragmentos menores e maiores), através da aplicação de uma corrente elétrica sobre uma matriz porosa. Esse campo elétrico faz com que as moléculas de DNA migrem do polo negativo para o polo positivo.')}>
                    Eletroforese
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Marcadores moleculares: Como vimos ao longo dos módulos, para realizar a identificação humana, são feitas análises de regiões específicas do DNA, não codificadoras. Para análises forenses, utilizamos os marcadores moleculares polimórficos para acessar essas regiões polimórficas, que veremos logo a seguir. Após essas análises, é elaborado um perfil genético único para cada indivíduo.')}>
                    Marcadores moleculares
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Comparação: É quando comparamos a amostra questionada coletada em local de crime com as amostras de referência do caso analisado. Dessa forma podemos descobrir se o DNA encontrado no local de crime coincide com o DNA do suspeito ou até mesmo para identificar restos mortais quando a vítima é desconhecida.')}>
                    Comparação
                  </TitleBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TitleBox onClick={() => handleOpenModal('Inserção no Banco de Perfis Genéticos: Após a análise do DNA, o perfil genético obtido pode ser inserido em bancos de dados de perfis genéticos, afim de confrontar tanto perfis genéticos obtidos em locais de crimes, quanto amostras-referência de vítimas, suspeitos e condenados. Os bancos de perfis genéticos podem ser utilizados para identificação de autoria, apontar crimes em série, além de colaborar na identificação de pessoas desaparecidas e de restos mortais não identificados.')}>
                    Inserção no Banco de Perfis Genéticos
                  </TitleBox>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RESUMO:
              </SubHeading>
              <Paragraph variant="body1">
                Nesse módulo, nós aprendemos sobre as principais técnicas de análise de DNA empregadas na Genética Forense, os diferentes tipos de polimorfismos que podem ser empregados na identificação humana, e conhecemos os softwares e bancos de dados disponíveis para análise e comparação de perfis genéticos no Brasil.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                REFERÊNCIAS:
              </SubHeading>
              <Paragraph variant="body1">
                CARRATO, T. M.; MENDES-JUNIOR, C. T. Um novo uso do DNA na resolução de crimes: predição de características morfológicas de suspeitos. Genética na Escola, Ribeirão Preto, v. 16, n. 2, p. 208-217, 2021. <br />
                DIAS FILHO, C. R.; et al. Introdução à Genética Forense. Campinas, SP: Millenium Editora, 2020. <br />
                DOS SANTOS, A. E. As principais linhas da biologia forense e como auxiliam na resolução de crimes. Revista brasileira de criminalística, v. 7, n. 3, p. 12-20, 2018. <br />
                GRIFFITHS, A. J. F.; WESSLER, S. R.; CARROL, S. B; et al. Introdução à Genética. 10ª Edição. Rio de Janeiro: Ed Guanabara Koogan, 2013. <br />
                MARANO, L. A.; SIMÕES, A. L.; OLIVEIRA, S. L; MENDES-JUNIOR, C. T. Polimorfismos Genéticos e Identificação Humana: o DNA como prova forense. Genética na Escola, Ribeirão Preto, v. 5, n. 1, p. 53-56, 2010.
              </Paragraph>
            </CardContent>
          </Card>
        </BackgroundBox>
      </Container>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent.split(': ')[0]}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent.split(': ')[1]}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Fechar
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
};

export default DNAAnalysis;
