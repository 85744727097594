import React from 'react';
import { Box, Button, Typography, Container, CssBaseline, ThemeProvider } from '@mui/material';
import darkTheme from './theme';

function Aviso({ onAccept, onDecline }) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={{ mt: 4, overflowY: 'auto', maxHeight: '80vh', padding: 2, border: '1px solid grey', borderRadius: '8px' }}>
          <Typography variant="h6" gutterBottom>Alerta de conteúdo sensível:</Typography>
          <Typography variant="body1" paragraph>
            Atenção!
            Antes de prosseguirmos, queremos que você saiba que o app Helix é um produto educacional, voltado para o ensino de Genética Molecular na etapa do Ensino Médio.
          </Typography>
          <Typography variant="body1" paragraph>
            A classificação indicativa do presente aplicativo é A14, ou seja, não é recomendada para menores de 14 anos, por apresentar descritores que envolvem atos criminosos e/ou emprego de violência, podendo conter elementos fictícios como morte acidental, sangue, ossadas, cadáveres (corpo das vítimas) e outros vestígios, destinados a criar um ambiente de aprendizado imersivo e realista.
          </Typography>
          <Typography variant="body1" paragraph>
            Entendemos que esses elementos podem ser sensíveis para alguns usuários, portanto, caso se considere mais sensível a esse tipo de conteúdo recomendamos que não abra e/ou participe das atividades que apresentam simulações e estudos de caso, de modo a evitar situações que lhe gerem gatilhos emocionais. No entanto, a decisão de prosseguir e continuar participando dessas atividades é totalmente sua.
          </Typography>
          <Typography variant="body1" paragraph>
            Nosso objetivo é que todo mundo se sinta confortável e seguro durante o aprendizado! Se tiver alguma dúvida ou preocupação, não hesite em conversar com seu(a) professor(a).
          </Typography>
          <Typography variant="body1" paragraph>
            Aceita vir conosco para descobrir como a genética pode auxiliar na resolução de casos forenses?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button variant="contained" color="primary" onClick={onAccept}>Aceito</Button>
            <Button variant="outlined" color="secondary" onClick={onDecline}>Não vou participar</Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Aviso;
