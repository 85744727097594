import React from 'react';
import { Container, Box, Typography, Button, Card, CardContent, CssBaseline, ThemeProvider, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importe o useNavigate
import { styled } from '@mui/material/styles';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const ResponsiveIframe = styled('iframe')({
  width: '100%',
  height: 'auto',
  aspectRatio: '16/9',
  border: 0,
  allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
});

const CrimeSceneInvestigation = () => {
  const navigate = useNavigate(); // Defina o navigate usando useNavigate

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            Investigando uma Cena de Crime
          </Heading>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Crime-scene-do-not-cross-wikimedia-scaled.jpg" alt="Investigando uma Cena de Crime" style={{ width: '50%', borderRadius: '8px' }} />
            <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
              Kat Wilcox, CC0, via Wikimedia Commons
            </Typography>
          </Box>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTAÇÃO:
              </SubHeading>
              <Paragraph variant="body1">
                Vamos colocar nossas habilidades de investigação à prova? Neste módulo, nós iremos mergulhar em um mundo de investigação e mistério e, para isso, analisaremos uma simulação de cena de crime em realidade virtual.
              </Paragraph>
            </CardContent>
          </Card>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                OBJETIVOS:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Observar e analisar uma cena de crime; <br />
                ✓ Levantar hipóteses sobre o que pode ter ocorrido na cena;
              </Paragraph>
            </CardContent>
          </Card>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                Alerta de conteúdo sensível: Simulação de Cena de Crime em Realidade Virtual
              </SubHeading>
              <Paragraph variant="body1">
                Atenção! Antes de prosseguirmos, queremos que você saiba que a Simulação de Cena de Crime em Realidade Virtual a seguir, contém elementos fictícios, como sangue, corpo da vítima e outros vestígios, destinados a criar um ambiente de aprendizado envolvente e realista.
              </Paragraph>
              <Paragraph variant="body1">
                Entendemos que esses elementos podem ser sensíveis para alguns usuários, portanto, caso se considere mais sensível a esse tipo de conteúdo recomendamos que não abra e/ou participe da etapa seguinte, de modo a evitar situações que lhe gerem gatilhos emocionais. No entanto, a decisão de prosseguir e continuar participando dessa atividade é totalmente sua.
              </Paragraph>
              <Paragraph variant="body1">
                Nosso objetivo é que todo mundo se sinta confortável e seguro durante o aprendizado! Se tiver alguma dúvida ou preocupação, não hesite em conversar com seu(a) professor(a).
              </Paragraph>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <StyledButton variant="outlined" href="https://kuula.co/share/7c0Cl/collection/7l8nx?fs=1&vr=1&sd=1&initload=1&thumbs=1&chromeless=1&logo=0&gc=1" target="_blank" rel="noopener">
                  Vou Participar
                </StyledButton>
                <StyledButton variant="outlined" onClick={() => navigate('/home')}>
                  Não Vou Participar
                </StyledButton>
              </Box>
            </CardContent>
          </Card>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                ATIVIDADES:
              </SubHeading>
              <Paragraph variant="body1">
                Analisem a seguir a simulação de uma cena de crime em realidade virtual. Nela, podemos encontrar diferentes tipos de pistas e vestígios que nos ajudarão a desvendar o que pode ter acontecido.
              </Paragraph>
              <Paragraph variant="body1">
                Observe o cenário apresentado com atenção e tente responder às seguintes perguntas:
              </Paragraph>
              <Paragraph variant="body1">
                • O que você vê? Descreva com suas palavras quais são as suas percepções sobre o cenário encontrado. <br />
                • O que você pensa sobre o que está vendo? O que você acha que pode ter ocorrido? <br />
                • O que isso te faz perguntar ou imaginar? Que dúvidas esse cenário te desperta?
              </Paragraph>
              <Paragraph variant="body1">
                Utilize o espaço disponível no <strong>Fórum 1 - Módulo 1 – “O que eu vejo”</strong> para registrar suas respostas conforme modelo abaixo: “Eu vejo..., (com base no que vejo, ) Eu penso que..., / (com base no que vejo e penso, ) Eu pergunto se… ou imagino se…?”
              </Paragraph>
              <Link href="https://kuula.co/share/7c0Cl/collection/7l8nx?fs=1&vr=1&sd=1&initload=1&thumbs=1&chromeless=1&logo=0&gc=1" target="_blank" rel="noopener">
                Simulação de Cena de Crime em Realidade Virtual
              </Link>
            </CardContent>
          </Card>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RESUMO:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Nesse módulo nós aprendemos a observar uma cena de crime e elencar possíveis marcas e objetos que possam ter relação com o evento ocorrido, além de utilizá-las para tentar explicar a dinâmica do fato.
              </Paragraph>
            </CardContent>
          </Card>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RECURSOS:
              </SubHeading>
              <Paragraph variant="body1">
                Você pode continuar explorando suas habilidades de investigação analisando a cena de crime em realidade virtual ou no vídeo “ID Virtual Crime Scene”, ambas a disponíveis nos links abaixo:
              </Paragraph>
              <Link href="https://kuula.co/share/7c0Cl/collection/7l8nx?fs=1&vr=1&sd=1&initload=1&thumbs=1&chromeless=1&logo=0&gc=1" target="_blank" rel="noopener">
                Simulação de Cena de Crime em Realidade Virtual
              </Link>
              <Box sx={{ mt: 2 }}>
                <ResponsiveIframe
                  src="https://www.youtube.com/embed/TPcaxKfJYaM?si=CXk8fasqaYg7dDjd"
                  title="YouTube video player"
                  allowFullScreen
                ></ResponsiveIframe>
              </Box>
            </CardContent>
          </Card>
          
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                REFERÊNCIAS:
              </SubHeading>
              <Paragraph variant="body1">
                PROJETO ZERO – FACULDADE DE EDUCAÇÃO DA UNIVERSIDADE DE HARVARD. A rotina de pensamento Vejo, Penso, Pergunto. 2022 Projeto Pensamento Visível (Visible Thinking) do Projeto Zero da Faculdade de Educação da Universidade de Harvard. Disponível em: 
                <Link href="https://pz.harvard.edu/resources/see-think-wonder" target="_blank" rel="noopener">
                  https://pz.harvard.edu/resources/see-think-wonder
                </Link>. Acesso em: 25 jun. 2023.
              </Paragraph>
            </CardContent>
          </Card>
        </BackgroundBox>
      </Container>
    </ThemeProvider>
  );
}

export default CrimeSceneInvestigation;
