import React, { useState } from 'react';
import { Container, Box, Typography, Card, CardContent, Button, CssBaseline, ThemeProvider, Modal, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  marginBottom: theme.spacing(2),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const ForensicCases = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent('');
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            Periciando (Estudos de Caso)
          </Heading>
          <Box textAlign="center" mb={4}>
            <img 
              src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Fingerprint-magnabrush-scaled.jpg" 
              alt="Daekow, CC BY-SA 4.0, via Wikimedia Commons" 
              width="654"
              height="436"
            />
          </Box>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTAÇÃO:
              </SubHeading>
              <Paragraph variant="body1">
                Vocês serão apresentados a casos fictícios, baseados em ocorrências comuns na atuação pericial.
                Cada grupo receberá um caso, contendo as informações relacionadas, as amostras que foram coletadas e então deverão proceder à perícia.
                Para isso, apliquem os conhecimentos adquiridos nos módulos anteriores para analisar as amostras e elaborar um Laudo Pericial com a conclusão para o caso.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                OBJETIVOS:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Identificar e reconhecer amostras biológicas relevantes para a resolução de casos forenses; <br />
                ✓ Aplicar técnicas de coleta, processamento e análise de DNA; <br />
                ✓ Comparar perfis genéticos obtidos para identificar suspeitos, vítimas ou pessoas desaparecidas; <br />
                ✓ Comunicar os resultados de forma clara e objetiva por meio de Laudo Pericial, utilizando o Método Científico.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                CONTEÚDOS:
              </SubHeading>
              <Paragraph variant="body1">
                Antes de prosseguirmos para os Estudos de Caso, é importante que conheçamos a peça mais importante de uma perícia: o Laudo Pericial!
              </Paragraph>
              <TitleBox onClick={() => handleOpenModal('De acordo com o Artigo 160 do Código de Processo Penal (CPP) brasileiro: "Os peritos elaborarão o laudo pericial, onde descreverão minuciosamente o que examinarem, e responderão aos quesitos formulados." Portanto, ao analisar um caso forense, os peritos precisam elaborar o Laudo Pericial, que é um documento onde serão apresentadas as amostras e objetos que foram coletados, os exames que foram realizados, as análises dos resultados e as conclusões a que os peritos chegaram. Esse documento é importante, pois através dele é que se apresenta a prova material de cada caso, a qual o Juiz irá utilizar para basear suas decisões e aplicar a justiça.')}>
                Laudo Pericial - Importância e Estrutura
              </TitleBox>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                ESTUDOS DE CASO:
              </SubHeading>
              <Paragraph variant="body1">
                Vocês já sabem qual é o papel da Genética Forense, quais são as etapas envolvidas na análise do DNA e compreenderam como se elabora um Laudo Pericial, agora é hora de aplicar esses conhecimentos para analisar um estudo de caso forense!
              </Paragraph>
              <TitleBox onClick={() => handleOpenModal('Estudo de caso 1 - Assalto à Banco: Os peritos criminais investigam um assalto à banco, coletam vestígios e tentam identificar a autoria do crime.')}>
                Estudo de Caso 1 - Assalto à Banco
              </TitleBox>
              <TitleBox onClick={() => handleOpenModal('Estudo de caso 2 - Pessoa Desaparecida: Na esperança de encontrar sua filha desaparecida, uma família busca pela ajuda da Polícia Científica e das tecnologias de análise do DNA.')}>
                Estudo de Caso 2 - Pessoa Desaparecida
              </TitleBox>
              <TitleBox onClick={() => handleOpenModal('Estudo de caso 3 - Acidente de Trânsito: Um trágico acidente causado pela colisão entre dois veículos deixa vítimas sem identificação.')}>
                Estudo de Caso 3 - Acidente de Trânsito
              </TitleBox>
              <TitleBox onClick={() => handleOpenModal('Estudo de caso 4 - Ossada Encontrada: Uma ossada humana sem identificação foi encontrada, os Peritos realizam análises para desvendar a quem pertence a ossada e elucidar as circunstâncias de sua morte.')}>
                Estudo de Caso 4 - Ossada Encontrada
              </TitleBox>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTANDO OS RESULTADOS:
              </SubHeading>
              <Paragraph variant="body1">
                Agora que seu grupo já fez a análise do estudo de caso e elaborou o Laudo Pericial, vocês precisam apresentar os resultados e conclusões obtidas para os demais colegas de sala e para o(a) professor(a).
                Em conjunto, decidam quais das opções abaixo vocês irão escolher para apresentar o caso analisado e as conclusões obtidas:
              </Paragraph>
              <TitleBox onClick={() => handleOpenModal('TRIBUNAL: Em conjunto com a turma, simulem o julgamento do caso analisado, representando os principais personagens como o Juiz, os advogados de defesa e acusação, os peritos, suspeitos e vítimas.')}>
                Tribunal
              </TitleBox>
              <TitleBox onClick={() => handleOpenModal('OITIVA DOS PERITOS: Simulem a convocação dos peritos para prestar esclarecimentos sobre a prova pericial ou para responder aos quesitos elaborados pelas partes envolvidas no processo judicial do caso analisado.')}>
                Oitiva dos Peritos
              </TitleBox>
              <TitleBox onClick={() => handleOpenModal('APRESENTAÇÃO DE SLIDES: Elaborem uma apresentação em slides contendo o histórico do caso analisado, as evidências, os exames que foram realizados e as conclusões a que chegaram através da perícia. Usem imagens para ilustrar o caso.')}>
                Apresentação de Slides
              </TitleBox>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RESUMO:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Nesse módulo nós tivemos a oportunidade de analisar de forma crítica estudos de casos forenses. <br />
                ✓ Aprendemos a elaborar laudos periciais e solucionar esses casos utilizando os conceitos de Genética Forense e técnicas de Biologia Molecular para análises de DNA. <br />
                ✓ Formulamos e testamos hipóteses sobre o desfecho dos estudos de caso. <br />
                ✓ Descobrimos a importância dos Bancos de perfis genéticos na identificação de suspeitos, na busca por pessoas desaparecidas e identificação de restos mortais não identificados. <br />
                ✓ Por fim, desenvolvemos as habilidades de comunicação para apresentar os resultados obtidos na elucidação dos casos forenses.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                REFERÊNCIAS:
              </SubHeading>
              <Paragraph variant="body1">
                DIAS FILHO, C. R.; et al. Introdução à Genética Forense. Campinas, SP: Millenium Editora, 2020. <br />
                DOS REIS, A. B. Metodologia Científica em Perícia Criminal. 3. ed. Campinas/SP: Millennium Editora, 2016. <br />
                SENADO FEDERAL. Código de Processo Penal (CPP). 3. ed. Brasília, DF: Senado Federal, Coordenação de Edições Técnicas, 2020. 200 p.
              </Paragraph>
            </CardContent>
          </Card>
        </BackgroundBox>
      </Container>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent.split(': ')[0]}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent.split(': ')[1]}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Fechar
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}

export default ForensicCases;
