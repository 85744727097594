import React, { useState } from 'react';
import { Container, Box, Typography, Card, CardContent, CssBaseline, ThemeProvider, Modal, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import darkTheme from './theme';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const ClickableBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontWeight: 'bold',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const DropArea = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  minHeight: '50px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: `2px dashed ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const words = [
  { id: 'word-1', content: 'ALELOS' },
  { id: 'word-2', content: 'DNA' },
  { id: 'word-3', content: 'LOCUS' },
  { id: 'word-4', content: 'MUTAÇÕES' },
  { id: 'word-5', content: 'POLIMORFISMOS DO DNA' },
];

const BasicGenetics = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [draggedWords, setDraggedWords] = useState(words);
  const [placedWords, setPlacedWords] = useState({});

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent('');
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (destination.droppableId === 'drop-area') {
      setPlacedWords({
        ...placedWords,
        [destination.index]: draggedWords.find((word) => word.id === draggableId).content,
      });

      const newDraggedWords = Array.from(draggedWords);
      newDraggedWords.splice(source.index, 1);
      setDraggedWords(newDraggedWords);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            Conceitos Básicos em Genética
          </Heading>

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Dna-163466.jpg" alt="Conceitos Básicos em Genética" style={{ width: '100%', borderRadius: '8px' }} />
            <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
              PublicDomainPictures, CC0, via Wikimedia Commons
            </Typography>
          </Box>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTAÇÃO:
              </SubHeading>
              <Paragraph variant="body1">
                Neste módulo iremos revisar e conhecer alguns conceitos básicos em Genética que são importantes para compreendermos como a Biologia pode auxiliar na resolução de casos forenses.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                OBJETIVOS:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Compreender o que é o DNA, sua estrutura, função e importância para a Genética; <br />
                ✓ Reconhecer a importância das mutações para o surgimento de polimorfismos; <br />
                ✓ Identificar como os polimorfismos auxiliam na identificação humana.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                CONTEÚDOS:
              </SubHeading>
              <Paragraph variant="body1">
                <strong>CONCEITOS BÁSICOS</strong>
              </Paragraph>
              <Paragraph variant="body1">
                Apresentamos a seguir um <strong>Glossário</strong> com o significado de alguns termos que são importantes para construirmos a compreensão dos conteúdos que serão vistos nos próximos módulos.
              </Paragraph>
              <Paragraph variant="body1" sx={{ fontWeight: 'bold', color: darkTheme.palette.secondary.main }}>
                CLIQUE NAS CAIXAS ABAIXO PARA CONHECER OS PRINCIPAIS TERMOS RELACIONADOS A GENÉTICA
              </Paragraph>

              <ClickableBox onClick={() => handleOpenModal(
                "DNA: O Ácido Desoxirribonucleico (DNA) é a molécula da hereditariedade, formada por duas cadeias de nucleotídeos ligados que formam uma estrutura em dupla hélice. Os nucleotídeos, por sua vez, são formados por um açúcar desoxirribose, um grupo fosfato e uma base nitrogenada que pode ser: adenina (A), timina (T), guanina (G) e citosina (C)."
              )}>
                <strong>DNA</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "GENES: São trechos da molécula de DNA compostos por uma região transcrita e uma sequência reguladora que torna a transcrição possível. Os genes são ainda a unidade fundamental da hereditariedade, que leva a informação biológica de uma geração para a seguinte."
              )}>
                <strong>GENES</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "GENOMA: Constitui toda a informação genética de um organismo, presente no conjunto de cromossomos e codificada em seu DNA."
              )}>
                <strong>GENOMA</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "ALELOS: Os alelos são versões alternativas (diferentes) de um gene e que ocupam a mesma localização cromossômica (locus)."
              )}>
                <strong>ALELOS</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "GENÓTIPO: Corresponde ao conjunto de genes existentes em um organismo."
              )}>
                <strong>GENÓTIPO</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "FENÓTIPO: É a expressão ou manifestação de uma característica observável determinada pelo genótipo."
              )}>
                <strong>FENÓTIPO</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "HOMOZIGOTO: Termo utilizado para descrever um organismo que seja portador de par de alelos idênticos em um determinado locus. Ex: AA, aa."
              )}>
                <strong>HOMOZIGOTO</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "HETEROZIGOTO: Termo utilizado para descrever um organismo que tem um par de alelos diferentes em um determinado locus. Ex: Aa."
              )}>
                <strong>HETEROZIGOTO</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "CROMOSSOMOS: São estruturas encontradas no núcleo das células que compreendem a molécula de DNA, associada a proteínas, que tornam essa molécula altamente espiralada. Os seres humanos apresentam um conjunto de 23 pares de cromossomos, totalizando 46 cromossomos."
              )}>
                <strong>CROMOSSOMOS</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "LOCUS: O locus gênico é um local específico em um cromossomo onde um gene está localizado, ou seja, a sua posição."
              )}>
                <strong>LOCUS</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "MUTAÇÕES: São alterações aleatórias que ocorrem na sequência de DNA. As mutações podem ocorrer naturalmente, devido a erros no processamento do DNA ou como resultado da exposição à radiação e agentes químicos. As mutações são fonte de variação genética."
              )}>
                <strong>MUTAÇÕES</strong>
              </ClickableBox>
              <ClickableBox onClick={() => handleOpenModal(
                "POLIMORFISMOS GENÉTICOS: São as variações genéticas que aparecem como resultado do processo mutacional. Os polimorfismos são amplamente utilizados como marcadores genéticos para realizar a identificação humana através de análises do DNA."
              )}>
                <strong>POLIMORFISMOS GENÉTICOS</strong>
              </ClickableBox>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                ATIVIDADES:
              </SubHeading>
              <Paragraph variant="body1">
                <strong>1) Arraste as palavras-chave para preencher as lacunas do texto abaixo:</strong>
              </Paragraph>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="drop-area" direction="horizontal">
                  {(provided) => (
                    <DropArea ref={provided.innerRef} {...provided.droppableProps}>
                      {draggedWords.map((word, index) => (
                        <Draggable key={word.id} draggableId={word.id} index={index}>
                          {(provided) => (
                            <ClickableBox
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {word.content}
                            </ClickableBox>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </DropArea>
                  )}
                </Droppable>

                <Paragraph variant="body1">
                  O estudo do <strong>{placedWords[0] || '__________'}</strong> é o alvo das análises forenses com fins de identificação humana. Um determinado <strong>{placedWords[1] || '__________'}</strong> pode apresentar formas variadas, os <strong>{placedWords[2] || '__________'}</strong>, que são resultado de <strong>{placedWords[3] || '__________'}</strong> acumuladas ao longo do tempo. Ao analisarmos os <strong>{placedWords[4] || '__________'}</strong> em uma população, conseguimos determinar um perfil genético único para cada indivíduo, que são fundamentais para a identificação humana em casos forenses, permitindo solucionar crimes ao identificar autores e vítimas, elucidar acidentes e até mesmo localizar pessoas desaparecidas.
                </Paragraph>
              </DragDropContext>

              <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                VERIFICAR
              </Button>
              <Paragraph variant="body1" sx={{ color: darkTheme.palette.error.main, mt: 2 }}>
                Opa! Parece que você marcou algo errado, reflita sobre suas escolhas antes de prosseguir!
              </Paragraph>
              <Paragraph variant="body1" sx={{ mt: 2 }}>
                Correção: DNA – LOCUS – ALELOS – MUTAÇÕES – POLIMORFISMOS DO DNA
              </Paragraph>
              <Paragraph variant="body1">
                <strong>2) Classifique as afirmativas abaixo em verdadeiras (V) ou falsas (F):</strong>
              </Paragraph>
              <Paragraph variant="body1">
                Os polimorfismos genéticos são utilizados para identificar indivíduos em investigações criminais. <br />
                Todos os indivíduos possuem um perfil genético único, sendo impossível encontrar coincidências entre pessoas distintas. <br />
                São nas regiões codificantes presentes nas sequências repetitivas onde encontramos os sítios polimórficos que são utilizados na individualização de pessoas. <br />
                As mutações são alterações na sequência de DNA de um organismo. <br />
                O DNA é a molécula da hereditariedade, responsável por transmitir a informação biológica para as gerações seguintes.
              </Paragraph>
              <Paragraph variant="body1" sx={{ color: darkTheme.palette.success.main, mt: 2 }}>
                Correção: V – F – F – V – V
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RESUMO:
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Nesse módulo nós revisamos alguns conceitos básicos em Genética; <br />
                ✓ Aprendemos como as mutações geram os polimorfismos; <br />
                ✓ Compreendemos como esses marcadores polimórficos podem ser utilizados na identificação humana.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RECURSOS:
              </SubHeading>
              <Paragraph variant="body1">
                Imagem do módulo: PublicDomainPictures, CC0, via Wikimedia Commons
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                REFERÊNCIAS:
              </SubHeading>
              <Paragraph variant="body1">
                GRIFFITHS, A. J. F.; WESSLER, S. R.; CARROL, S. B; <em>et al</em>. <strong>Introdução à Genética</strong>. 10ª Edição. Rio de Janeiro: Ed Guanabara Koogan, 2013. <br />
                MARANO, L. A.; SIMÕES, A. L.; OLIVEIRA, S. L; MENDES-JUNIOR, C. T. <strong>Polimorfismos Genéticos e Identificação Humana: o DNA como prova forense</strong>. Genética na Escola, Ribeirão Preto, v. 5, n. 1, p. 53-56, 2010. <br />
                PIERCE, B. A. <strong>Genética:</strong> Um enfoque conceitual. 5ª Edição. Rio de Janeiro: Ed Guanabara Koogan, 2016. <br />
                RODRIGUES, E. L..; GARRIDO, R. G.. <strong>Polimorfismos genéticos e STRs</strong>. In: DIAS FILHO, C. R.; <em>et al</em>. <strong>Introdução à Genética Forense</strong>. Campinas, SP: Millenium Editora, 2020. Capítulo 2, p. 13-39.
              </Paragraph>
            </CardContent>
          </Card>
        </BackgroundBox>
      </Container>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent.split(':')[0]}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent.split(':')[1]}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Fechar
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}

export default BasicGenetics;
