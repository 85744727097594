import React from 'react';
import { Container, Box, Typography, List, ListItem, ListItemIcon, ListItemText, Link, CssBaseline, ThemeProvider } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import BookIcon from '@mui/icons-material/Book';
import PersonIcon from '@mui/icons-material/Person';
import darkTheme from './theme';
import backgroundImage from '../images/fundo_bg.png'; // Atualize o caminho para o correto

function Contact() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box
         sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 5,
        }}
      >
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, mb: 6 }}>
            <Typography variant="h4" gutterBottom>Fale Conosco</Typography>
            <List>
              <ListItem
                sx={{
                  border: `1px solid ${darkTheme.palette.primary.main}`, // Adiciona a borda na cor principal do tema
                  borderRadius: '8px', // Arredondamento dos cantos
                  mb: 2, // Margem inferior entre os itens
                  padding: '10px', // Espaçamento interno do item
                }}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Gabriela C S Campos" />
              </ListItem>
              <ListItem
                sx={{
                  border: `1px solid ${darkTheme.palette.primary.main}`,
                  borderRadius: '8px',
                  mb: 2,
                  padding: '10px',
                }}
              >
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText primary="Graduada em Licenciatura em Ciências Biológicas (UFMT - 2016)" />
              </ListItem>
              <ListItem
                sx={{
                  border: `1px solid ${darkTheme.palette.primary.main}`,
                  borderRadius: '8px',
                  mb: 2,
                  padding: '10px',
                }}
              >
                <ListItemIcon>
                  <BadgeIcon />
                </ListItemIcon>
                <ListItemText primary="Especialista em Perícia Criminal & Ciências Forenses (IPOG - 2021)" />
              </ListItem>
              <ListItem
                sx={{
                  border: `1px solid ${darkTheme.palette.primary.main}`,
                  borderRadius: '8px',
                  mb: 2,
                  padding: '10px',
                }}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText primary="Mestrado profissional em andamento em Ensino de Biologia (UFMT)" />
              </ListItem>
              <ListItem
                sx={{
                  border: `1px solid ${darkTheme.palette.primary.main}`,
                  borderRadius: '8px',
                  mb: 2,
                  padding: '10px',
                }}
              >
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link href="http://lattes.cnpq.br/5942774889898875" target="_blank" rel="noopener">
                    Currículo Lattes
                  </Link>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Contact;
