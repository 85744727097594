import React, { useState } from 'react';
import { Container, Box, Typography, Card, CardContent, Button, CssBaseline, ThemeProvider, Modal, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import darkTheme from './theme';

const BackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(4),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  textAlign: 'center',
  marginBottom: theme.spacing(2),
  fontSize: '1.75rem',
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  fontSize: '1.25rem',
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  borderRadius: '50%',
  width: theme.spacing(8),
  height: theme.spacing(8),
  backgroundColor: theme.palette.info.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.info.dark,
  },
  boxShadow: theme.shadows[6],
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: 24,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const BiologicalSamples = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedSamples, setSelectedSamples] = useState({
    unhas: false,
    solo: false,
    suor: false,
    documentos: false,
    insetos: false,
    fezes: false,
    sangue: false,
    saliva: false,
    armaDeFogo: false,
    armaBranca: false,
    tecido: false,
    pegadas: false,
    drogas: false,
    ossos: false,
    urina: false,
    polen: false,
    impressaoDigital: false,
    cabelosPelos: false,
    explosivos: false,
    semen: false,
    projetil: false,
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCheckboxChange = (event) => {
    setSelectedSamples({
      ...selectedSamples,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCheckAnswers = () => {
    const correctAnswers = {
      unhas: true,
      solo: false,
      suor: true,
      documentos: false,
      insetos: false,
      fezes: true,
      sangue: true,
      saliva: true,
      armaDeFogo: false,
      armaBranca: false,
      tecido: false,
      pegadas: false,
      drogas: false,
      ossos: true,
      urina: true,
      polen: false,
      impressaoDigital: false,
      cabelosPelos: true,
      explosivos: false,
      semen: true,
      projetil: false,
    };

    let isCorrect = true;
    Object.keys(correctAnswers).forEach((key) => {
      if (selectedSamples[key] !== correctAnswers[key]) {
        isCorrect = false;
      }
    });

    if (isCorrect) {
      alert("Parabéns! Todas as respostas estão corretas.");
    } else {
      alert("Opa! Parece que você marcou algo errado, reflita sobre suas escolhas antes de prosseguir!");
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container>
        <BackgroundBox>
          <Heading variant="h4" gutterBottom>
            Amostras Biológicas e Identificação Humana
          </Heading>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img
              src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Blood_Test_15575812743-scaled.jpg"
              alt="Amostras Biológicas e Identificação Humana"
              style={{ width: '100%', borderRadius: '8px' }}
            />
            <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
              Alden Chadwick from Leeds, UK, CC BY 2.0, via Wikimedia Commons
            </Typography>
          </Box>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                APRESENTAÇÃO
              </SubHeading>
              <Paragraph variant="body1">
                Dentre os vestígios encontrados em uma cena de crime, você sabe quais podem nos auxiliar a identificar as vítimas ou possíveis suspeitos?
                Neste módulo, iremos descobrir o que são amostras biológicas e qual a sua importância para a identificação humana.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                OBJETIVOS
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Reconhecer quais são os vestígios de origem biológica em uma cena de crime; <br />
                ✓ Compreender a importância dos procedimentos de coleta, armazenamento e análise desses vestígios para a identificação humana em casos forenses;
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                CONTEÚDOS
              </SubHeading>
              <Paragraph variant="body1">
                <strong>Observe esses dois rapazes! O que os diferencia um do outro?</strong>
              </Paragraph>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <img
                  src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/Imagem1-300x300.png"
                  alt="Gêmeos Idênticos"
                  style={{ width: '50%', borderRadius: '8px' }}
                />
                <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
                  Imagem gerada por Inteligência Artificial, utilizando o Criador de Imagens da Microsoft Bing, DALL-E 3 (2023).
                </Typography>
              </Box>

              <Paragraph variant="body1">
                Os dois rapazes são gêmeos idênticos! A princípio, ao tentar diferenciá-los, você pode elencar características físicas como a cor da pele, cor dos olhos, altura, barba, cor e textura dos cabelos, entre outras.
              </Paragraph>
              <Paragraph variant="body1">
                Agora reflita: E se você não tiver a imagem dessas pessoas para compará-las, mas somente amostras biológicas pertencentes a elas?
              </Paragraph>

              <Paragraph variant="body1">
                <strong>Agora observe as amostras biológicas 1 e 2:</strong>
              </Paragraph>
              <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 4 }}>
                <img
                  src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/respingo-de-sangue-freepik-1-1-255x300.jpg"
                  alt="Amostra 1"
                  style={{ width: '30%', borderRadius: '8px' }}
                />
                <img
                  src="https://www.apphelix.com.br/app/wp-content/uploads/2024/08/respingo-de-sangue-freepik-2-1-200x300.jpg"
                  alt="Amostra 2"
                  style={{ width: '30%', borderRadius: '8px' }}
                />
              </Box>

              <Paragraph variant="body1">
                <strong>Você seria capaz de definir a quem pertencem essas manchas de sangue somente ao observá-las?</strong>
              </Paragraph>

              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                  SIM
                </Button>
                <Button variant="contained" color="secondary">
                  NÃO
                </Button>
              </Box>

              <Paragraph variant="body1">
                <strong>Se você respondeu que não, você está certo!</strong> Existem três formas de identificar pessoas: por meio de Impressões digitais, através da Antropologia forense ou da Genética forense.
              </Paragraph>
              <Paragraph variant="body1">
                Quando não sabemos a quem pertence uma amostra biológica, podemos utilizar o DNA como uma ferramenta poderosa para auxiliar investigações criminais e realizar a identificação humana! Assim respondemos a pergunta <strong>“QUEM”</strong> do Heptâmetro de Quintiliano!
              </Paragraph>

              <Paragraph variant="body1">
                <strong>Vamos relembrar da cena de crime em realidade virtual analisada no Módulo 1?</strong>
              </Paragraph>
              <Paragraph variant="body1">
                Neste cenário, existem objetos que podem conter vestígios de natureza biológica. Essas pistas podem ser usadas como evidências importantes para identificar vítimas e possíveis suspeitos através da análise do DNA.
              </Paragraph>
              <Paragraph variant="body1">
                Retorne à cena de crime e reflita sobre quais dos objetos encontrados podem ser utilizados em exames de DNA.
              </Paragraph>

              <Paragraph variant="body1">
                <strong>Segundo o Artigo 158-A § 3º do Código de Processo Penal (CPP) brasileiro:</strong> <em>“Vestígio é todo objeto ou material bruto, visível ou latente, constatado ou recolhido, que se relaciona à infração penal.”</em>
              </Paragraph>

              <Paragraph variant="body1" sx={{ mt: 4, fontWeight: 'bold' }}>
                🔍 EXPLORE!
              </Paragraph>
              <Paragraph variant="body1">
                Você sabe o que é a <strong>“Cadeia de Custódia”</strong>? Pesquise quais são as etapas desse procedimento e descubra qual é a sua importância para a Perícia Criminal.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                ATIVIDADE
              </SubHeading>
              <Paragraph variant="body1">
                Observe os tipos de amostra apresentados abaixo. Quais você acredita que podem ser utilizados para identificação humana através de análises do DNA? Selecione todas as opções que julgar corretas.
              </Paragraph>

              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.keys(selectedSamples).map((sample, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedSamples[sample]}
                        onChange={handleCheckboxChange}
                        name={sample}
                      />
                    }
                    label={sample.toUpperCase().replace(/_/g, ' ')}
                  />
                ))}
              </Box>

              <Button variant="contained" color="primary" onClick={handleCheckAnswers} sx={{ mt: 2 }}>
                VERIFICAR
              </Button>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RESUMO
              </SubHeading>
              <Paragraph variant="body1">
                ✓ Nesse módulo nós aprendemos a reconhecer quais são as amostras biológicas que podem ser encontradas em uma cena de crime;
                ✓ Compreendemos como esses vestígios são utilizados para a identificação humana em casos forenses;
                ✓ Aprendemos como coletar e armazenar cada tipo de vestígios biológicos;
                ✓ Conhecemos o que é a Cadeia de Custódia e compreendemos a sua importância para o trabalho da Perícia Criminal;
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                RECURSOS
              </SubHeading>
              <Paragraph variant="body1">
                As imagens utilizadas nesse módulo foram obtidas nas seguintes plataformas:
              </Paragraph>
              <Paragraph variant="body1">
                Criador de Imagens da Microsoft Bing, DALL-E 3 (2023). Disponível em: https://www.bing.com/images/create. Acesso em: 26 jul. 2023.
              </Paragraph>
              <Paragraph variant="body1">
                Racool_studio no Freepik, 2023. Disponível em: https://br.freepik.com/fotos-gratis/respingo-de-sangue_6165402.htm#query=manchas%20de%20sangue&amp;position=19&amp;from_view=search&amp;track=ais&amp;uuid=f676fa33-02c0-4e7f-8ab9-b0de4bb0d859. Acesso em: 26 jul. 2023.
              </Paragraph>
              <Paragraph variant="body1">
                Racool_studio no Freepik, 2023. Disponível em: https://br.freepik.com/fotos-gratis/respingo-de-sangue_6165408.htm#query=manchas%20de%20sangue&amp;position=12&amp;from_view=search&amp;track=ais&amp;uuid=f676fa33-02c0-4e7f-8ab9-b0de4bb0d859. Acesso em: 26 jul. 2023.
              </Paragraph>
              <Paragraph variant="body1">
                Imagem deste módulo: Alden Chadwick from Leeds, UK, CC BY 2.0, via Wikimedia Commons.
              </Paragraph>
            </CardContent>
          </Card>

          <Card sx={{ mb: 4 }}>
            <CardContent>
              <SubHeading variant="h6" gutterBottom>
                REFERÊNCIAS
              </SubHeading>
              <Paragraph variant="body1">
                DIAS FILHO, C. R.; GARRIDO, R. G.; CÂNDIDO, I. M. Cadeia de custódia, coleta e acondicionamento de amostras forenses para fins de exame de DNA. In: DIAS FILHO, C. R.; et al. <strong>Introdução à Genética Forense</strong>. Campinas, SP: Millenium Editora, 2020. Capítulo 3, p.41-65.
              </Paragraph>
              <Paragraph variant="body1">
                DOS REIS, A. B. <strong>Metodologia Científica em Perícia Criminal</strong>. 3. ed. Série: Tratado de Perícias Criminalísticas. Campinas/SP: Millennium Editora, 2016.
              </Paragraph>
              <Paragraph variant="body1">
                RODRIGUES, E. L.; GARRIDO, R. G. Polimorfismos genéticos e STRs. In: DIAS FILHO, C. R.; et al. <strong>Introdução à Genética Forense</strong>. Campinas, SP: Millenium Editora, 2020. Capítulo 2, p.13-40.
              </Paragraph>
              <Paragraph variant="body1">
                SENADO FEDERAL. <strong>Código de Processo Penal (CPP)</strong>. 3. ed. Brasília, DF : Senado Federal, Coordenação de Edições Técnicas, 2020. 200 p. Disponível em: https://www2.senado.leg.br/bdsf/bitstream/handle/id/569703/codigo_de_processo_penal_3ed.pdf. Acesso em: 26 jul. 2023.
              </Paragraph>
            </CardContent>
          </Card>
        </BackgroundBox>
        <ExploreButton onClick={handleOpenModal}>
          <InfoIcon />
        </ExploreButton>
      </Container>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="explore-modal-title"
        aria-describedby="explore-modal-description"
      >
        <ModalContent>
          <Typography id="explore-modal-title" variant="h6" component="h2">
            Cadeia de Custódia
          </Typography>
          <Typography id="explore-modal-description" sx={{ mt: 2 }}>
            A Cadeia de Custódia é o procedimento utilizado para manter e documentar a cronologia da posse, controle, transferência, análise e disposição de provas físicas, a fim de garantir sua integridade durante todo o processo de investigação.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button onClick={handleCloseModal} variant="contained" color="primary">
              Fechar
            </Button>
          </Box>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}

export default BiologicalSamples;
